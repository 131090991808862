import React, { useState } from "react";
import "./Components.css";
import buttonBoarder from "../../images/assets/button-boarder.png";

interface NavMainProps {
  setCollectionType: React.Dispatch<React.SetStateAction<string>>;
}

const NavMain = (props: NavMainProps) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedButton, setSelectedButton] = useState("");

  // Function to toggle dropdown
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const buttons = [
    { id: "about", label: "About" },
    { id: "tattoos", label: "Tattoos" },
    { id: "ceramics", label: "Ceramics" },
    { id: "paintings", label: "Paintings" },
    { id: "drawings", label: "Drawings" },
    { id: "account", label: "Account" },
  ];

  return (
    <>
      <div className="nav-main">
        <div className="desktop-nav">
          {buttons.map((button) => (
            <button
              className={`nav-button ${
                selectedButton === button.id ? "selected-nav-button" : ""
              }`}
              onClick={() => {
                props.setCollectionType(button.id);
                setSelectedButton(button.id);
              }}
            >
              <p className="nav-button-text">{button.label}</p>
              <img
                src={buttonBoarder}
                className="nav-button-boarder"
                alt="boarder"
              />
            </button>
          ))}
        </div>
        <div className="mobile-nav">
          <button onClick={toggleDropdown} className="nav-button">
          <p className="nav-button-text">Menu</p>
                  <img
                    src={buttonBoarder}
                    className="nav-button-boarder"
                    alt="boarder"
                  />
          </button>
          {isDropdownOpen && (
            <div className="nav-dropdown-menu">
              {buttons.map((button) => (
                <button
                  className={`nav-button nav-button-mobile ${
                    selectedButton === button.id ? "selected-nav-button" : ""
                  }`}
                  onClick={() => {
                    props.setCollectionType(button.id);
                    setSelectedButton(button.id);
                  }}
                >
                  <p className="nav-button-text">{button.label}</p>
                  <img
                    src={buttonBoarder}
                    className="nav-button-boarder"
                    alt="boarder"
                  />
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default NavMain;
