import React from 'react';
import './Components.css';
import logo from '../../images/assets/yobyob sygyl.png';

const PageHeader = () => {

  return (
    <>
      <div className="page-header">
        <div className="logo-area-image">
          <img src={logo} className="logo" alt="logo" />
        </div>
      </div>
    </>
  );
};

export default PageHeader;
