import React from 'react';
import './Login.css';

const Login = () => {
  return (
    <>
      <h1>Login</h1>
    </>
  );
};

export default Login;
