import React, { useEffect, useState } from 'react';
import '../Components.css';
import tattoo1 from '../../../images/complete/tattoos/CopyofACAPS02_Magritte_0549_001_pages-to-jpg-0001.jpg';
import tattoo2 from '../../../images/complete/tattoos/Copy of ACAPS02_Magritte_0549_001_pages-to-jpg-0002.jpg';
import tattoo3 from '../../../images/complete/tattoos/Copy of ACAPS02_Magritte_0549_001_pages-to-jpg-0003.jpg';
import tattoo4 from '../../../images/complete/tattoos/Copy of ACAPS02_Magritte_0549_001_pages-to-jpg-0004.jpg';
import tattoo5 from '../../../images/complete/tattoos/Copy of ACAPS02_Magritte_0549_001_pages-to-jpg-0005.jpg';
import tattoo6 from '../../../images/complete/tattoos/Copy of ACAPS02_Magritte_0549_001_pages-to-jpg-0006.jpg';
import tattoo7 from '../../../images/complete/tattoos/Copy of ACAPS02_Magritte_0549_001_pages-to-jpg-0007.jpg';
import tattoo8 from '../../../images/complete/tattoos/Copy of ACAPS02_Magritte_0549_001_pages-to-jpg-0008.jpg';
import tattoo9 from '../../../images/complete/tattoos/Copy of ACAPS02_Magritte_0549_001_pages-to-jpg-0009.jpg';
import tattoo10 from '../../../images/complete/tattoos/Copy of ACAPS02_Magritte_0549_001_pages-to-jpg-0010.jpg';
import tattoo11 from '../../../images/complete/tattoos/Copy of ACAPS02_Magritte_0549_001_pages-to-jpg-0011.jpg';
import tattoo12 from '../../../images/complete/tattoos/Copy of ACAPS02_Magritte_0549_001_pages-to-jpg-0012.jpg';
import tattoo13 from '../../../images/complete/tattoos/Copy of ACAPS02_Magritte_0549_001_pages-to-jpg-0013.jpg';
import tattoo14 from '../../../images/complete/tattoos/Copy of ACAPS02_Magritte_0549_001_pages-to-jpg-0014.jpg';
import tattoo15 from '../../../images/complete/tattoos/Copy of ACAPS02_Magritte_0549_001_pages-to-jpg-0015.jpg';
import tattoo16 from '../../../images/complete/tattoos/Copy of ACAPS02_Magritte_0549_001_pages-to-jpg-0016.jpg';
import tattoo17 from '../../../images/complete/tattoos/Copy of ACAPS02_Magritte_0549_001_pages-to-jpg-0017.jpg';
import tattoo18 from '../../../images/complete/tattoos/Copy of ACAPS02_Magritte_0549_001_pages-to-jpg-0018.jpg';
import tattoo19 from '../../../images/complete/tattoos/Copy of ACAPS02_Magritte_0549_001_pages-to-jpg-0019.jpg';
import tattoo20 from '../../../images/complete/tattoos/Copy of ACAPS02_Magritte_0549_001_pages-to-jpg-0020.jpg';
import tattoo21 from '../../../images/complete/tattoos/Copy of ACAPS02_Magritte_0549_001_pages-to-jpg-0021.jpg';
import tattoo22 from '../../../images/complete/tattoos/Copy of ACAPS02_Magritte_0552_001_pages-to-jpg-0001.jpg';
import tattoo23 from '../../../images/complete/tattoos/Copy of ACAPS02_Magritte_0552_001_pages-to-jpg-0002.jpg';
import tattoo24 from '../../../images/complete/tattoos/Copy of ACAPS02_Magritte_0552_001_pages-to-jpg-0003.jpg';
import tattoo25 from '../../../images/complete/tattoos/Copy of ACAPS02_Magritte_0552_001_pages-to-jpg-0004.jpg';
import tattoo26 from '../../../images/complete/tattoos/ilovepdf_merged_page-0001.jpg';
import tattoo27 from '../../../images/complete/tattoos/ilovepdf_merged_page-0002.jpg';
import tattoo28 from '../../../images/complete/tattoos/ilovepdf_merged_page-0003.jpg';
import tattoo29 from '../../../images/complete/tattoos/ilovepdf_merged_page-0004.jpg';
import tattoo30 from '../../../images/complete/tattoos/ilovepdf_merged_page-0005.jpg';
import tattoo31 from '../../../images/complete/tattoos/ilovepdf_merged_page-0006.jpg';
import tattoo32 from '../../../images/complete/tattoos/ilovepdf_merged_page-0007.jpg';
import tattoo33 from '../../../images/complete/tattoos/ilovepdf_merged_page-0008.jpg';
import tattoo34 from '../../../images/complete/tattoos/ilovepdf_merged_page-0009.jpg';
import tattoo35 from '../../../images/complete/tattoos/ilovepdf_merged_page-0010.jpg';
import tattoo36 from '../../../images/complete/tattoos/ilovepdf_merged_page-0011.jpg';
import tattoo37 from '../../../images/complete/tattoos/ilovepdf_merged_page-0012.jpg';
import tattoo38 from '../../../images/complete/tattoos/ilovepdf_merged_page-0013.jpg';
import tattoo39 from '../../../images/complete/tattoos/ilovepdf_merged_page-0014.jpg';
import tattoo40 from '../../../images/complete/tattoos/ilovepdf_merged_page-0015.jpg';

const tattooArray: { url: string; desc: string }[] = [
  { url: tattoo1, desc: '' },
  { url: tattoo2, desc: '' },
  { url: tattoo3, desc: '' },
  { url: tattoo4, desc: '' },
  { url: tattoo5, desc: '' },
  { url: tattoo6, desc: '' },
  { url: tattoo7, desc: '' },
  { url: tattoo8, desc: '' },
  { url: tattoo9, desc: '' },
  { url: tattoo10, desc: '' },
  { url: tattoo11, desc: '' },
  { url: tattoo12, desc: '' },
  { url: tattoo13, desc: '' },
  { url: tattoo14, desc: '' },
  { url: tattoo15, desc: '' },
  { url: tattoo16, desc: '' },
  { url: tattoo17, desc: '' },
  { url: tattoo18, desc: '' },
  { url: tattoo19, desc: '' },
  { url: tattoo20, desc: '' },
  { url: tattoo21, desc: '' },
  { url: tattoo22, desc: '' },
  { url: tattoo23, desc: '' },
  { url: tattoo24, desc: '' },
  { url: tattoo25, desc: '' },
  { url: tattoo26, desc: '' },
  { url: tattoo27, desc: '' },
  { url: tattoo28, desc: '' },
  { url: tattoo29, desc: '' },
  { url: tattoo30, desc: '' },
  { url: tattoo31, desc: '' },
  { url: tattoo32, desc: '' },
  { url: tattoo33, desc: '' },
  { url: tattoo34, desc: '' },
  { url: tattoo35, desc: '' },
  { url: tattoo36, desc: '' },
  { url: tattoo37, desc: '' },
  { url: tattoo38, desc: '' },
  { url: tattoo39, desc: '' },
  { url: tattoo40, desc: '' },
];

const drawingArray: { url: string; desc: string }[] = [];
const ceramicArray: { url: string; desc: string }[] = [];
const paintingArray: { url: string; desc: string }[] = [];
const emptyArray: { url: string; desc: string }[] = [];

/* Append rest of images */

interface ImageDisplayerProps {
  collectionType: string;
  pageToShow: number;
  setPageToShow: (page: number) => void;
  setShowViewImage: () => void;
  setImageToShow: (image: { url: string; desc: string }) => void;
}

const ImageDisplayer = (props: ImageDisplayerProps) => {
  const [activeArray, setActiveArray] = useState(drawingArray);
  const imagesPerPage = 17;

  useEffect(() => {
    console.log('OI Its changed to ', props.collectionType);
    switch (props.collectionType) {
      case 'drawings':
        setActiveArray(drawingArray);
        break;
      case 'ceramics':
        setActiveArray(ceramicArray);
        break;
      case 'paintings':
        setActiveArray(paintingArray);
        break;
      case 'tattoos':
        setActiveArray(tattooArray);
        break;
      case 'about':
      case 'account':
        setActiveArray(emptyArray);
        break;
      default:
        console.log('You done messed up');
    }
  }, [props.collectionType]);

  // Calculate the images to display for the current page
  const indexOfLastImage = props.pageToShow * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = activeArray.slice(indexOfFirstImage, indexOfLastImage);
  const totalPages = Math.ceil(activeArray.length / imagesPerPage);

  return (
    <div className="image-displayer">
      <div className="image-container-container">
        <div className="row row-1">
          {currentImages[0] && (
            <div className="image-container" key={0}>
              <img
                src={currentImages[0].url}
                className={`image${0 + 1}`}
                alt={`${0 + 1}`}
                onClick={() => {
                  props.setShowViewImage();
                  props.setImageToShow(currentImages[0]);
                }}
              />
              <p>{currentImages[0].desc}</p>
            </div>
          )}
          {currentImages[1] && (
            <div className="image-container" key={1}>
              <img
                src={currentImages[1].url}
                className={`image${1 + 1}`}
                alt={`${1 + 1}`}
                onClick={() => {
                  props.setShowViewImage();
                  props.setImageToShow(currentImages[1]);
                }}
              />
              <p>{currentImages[1].desc}</p>
            </div>
          )}
          {currentImages[2] && (
            <div className="image-container" key={2}>
              <img
                src={currentImages[2].url}
                className={`image${2 + 1}`}
                alt={`${2 + 1}`}
                onClick={() => {
                  props.setShowViewImage();
                  props.setImageToShow(currentImages[2]);
                }}
              />
              <p>{currentImages[2].desc}</p>
            </div>
          )}
          {currentImages[3] && (
            <div className="image-container" key={3}>
              <img
                src={currentImages[3].url}
                className={`image${3 + 1}`}
                alt={`${3 + 1}`}
                onClick={() => {
                  props.setShowViewImage();
                  props.setImageToShow(currentImages[3]);
                }}
              />
              <p>{currentImages[3].desc}</p>
            </div>
          )}
        </div>

        <div className="row row-2">
          {currentImages[4] && (
            <div className="image-container" key={4}>
              <img
                src={currentImages[4].url}
                className={`image${4 + 1}`}
                alt={`${4 + 1}`}
                onClick={() => {
                  props.setShowViewImage();
                  props.setImageToShow(currentImages[4]);
                }}
              />
              <p>{currentImages[4].desc}</p>
            </div>
          )}
          {currentImages[5] && (
            <div className="image-container" key={5}>
              <img
                src={currentImages[5].url}
                className={`image${5 + 1}`}
                alt={`${5 + 1}`}
                onClick={() => {
                  props.setShowViewImage();
                  props.setImageToShow(currentImages[5]);
                }}
              />
              <p>{currentImages[5].desc}</p>
            </div>
          )}
          {currentImages[6] && (
            <div className="image-container" key={6}>
              <img
                src={currentImages[6].url}
                className={`image${6 + 1}`}
                alt={`${6 + 1}`}
                onClick={() => {
                  props.setShowViewImage();
                  props.setImageToShow(currentImages[6]);
                }}
              />
              <p>{currentImages[6].desc}</p>
            </div>
          )}
          {currentImages[7] && (
            <div className="image-container" key={7}>
              <img
                src={currentImages[7].url}
                className={`image${7 + 1}`}
                alt={`${7 + 1}`}
                onClick={() => {
                  props.setShowViewImage();
                  props.setImageToShow(currentImages[7]);
                }}
              />
              <p>{currentImages[7].desc}</p>
            </div>
          )}
        </div>

        <div className="row row-3">
          {currentImages[8] && (
            <div className="image-container" key={8}>
              <img
                src={currentImages[8].url}
                className={`image${8 + 1}`}
                alt={`${8 + 1}`}
                onClick={() => {
                  props.setShowViewImage();
                  props.setImageToShow(currentImages[8]);
                }}
              />
              <p>{currentImages[8].desc}</p>
            </div>
          )}
          {currentImages[9] && (
            <div className="image-container" key={9}>
              <img
                src={currentImages[9].url}
                className={`image${9 + 1}`}
                alt={`${9 + 1}`}
                onClick={() => {
                  props.setShowViewImage();
                  props.setImageToShow(currentImages[9]);
                }}
              />
              <p>{currentImages[9].desc}</p>
            </div>
          )}
          {currentImages[10] && (
            <div className="image-container" key={10}>
              <img
                src={currentImages[10].url}
                className={`image${10 + 1}`}
                alt={`${10 + 1}`}
                onClick={() => {
                  props.setShowViewImage();
                  props.setImageToShow(currentImages[10]);
                }}
              />
              <p>{currentImages[10].desc}</p>
            </div>
          )}
        </div>

        <div className="row row-4">
          {currentImages[11] && (
            <div className="image-container" key={11}>
              <img
                src={currentImages[11].url}
                className={`image${11 + 1}`}
                alt={`${11 + 1}`}
                onClick={() => {
                  props.setShowViewImage();
                  props.setImageToShow(currentImages[11]);
                }}
              />
              <p>{currentImages[11].desc}</p>
            </div>
          )}
          {currentImages[12] && (
            <div className="image-container" key={12}>
              <img
                src={currentImages[12].url}
                className={`image${12 + 1}`}
                alt={`${12 + 1}`}
                onClick={() => {
                  props.setShowViewImage();
                  props.setImageToShow(currentImages[12]);
                }}
              />
              <p>{currentImages[12].desc}</p>
            </div>
          )}
          {currentImages[13] && (
            <div className="image-container" key={13}>
              <img
                src={currentImages[13].url}
                className={`image${13 + 1}`}
                alt={`${13 + 1}`}
                onClick={() => {
                  props.setShowViewImage();
                  props.setImageToShow(currentImages[13]);
                }}
              />
              <p>{currentImages[13].desc}</p>
            </div>
          )}
        </div>

        <div className="row row-5">
          {currentImages[14] && (
            <div className="image-container" key={14}>
              <img
                src={currentImages[14].url}
                className={`image${14 + 1}`}
                alt={`${14 + 1}`}
                onClick={() => {
                  props.setShowViewImage();
                  props.setImageToShow(currentImages[14]);
                }}
              />
              <p>{currentImages[14].desc}</p>
            </div>
          )}
          {currentImages[15] && (
            <div className="image-container" key={15}>
              <img
                src={currentImages[15].url}
                className={`image${15 + 1}`}
                alt={`${15 + 1}`}
                onClick={() => {
                  props.setShowViewImage();
                  props.setImageToShow(currentImages[15]);
                }}
              />
              <p>{currentImages[15].desc}</p>
            </div>
          )}
        </div>

        <div className="row row-6">
          {currentImages[16] && (
            <div className="image-container" key={16}>
              <img
                src={currentImages[16].url}
                className={`image${16 + 1}`}
                alt={`${16 + 1}`}
                onClick={() => {
                  props.setShowViewImage();
                  props.setImageToShow(currentImages[16]);
                }}
              />
              <p>{currentImages[16].desc}</p>
            </div>
          )}
          {currentImages[17] && (
            <div className="image-container" key={17}>
              <img
                src={currentImages[17].url}
                className={`image${17 + 1}`}
                alt={`${17 + 1}`}
                onClick={() => {
                  props.setShowViewImage();
                  props.setImageToShow(currentImages[17]);
                }}
              />
              <p>{currentImages[17].desc}</p>
            </div>
          )}
        </div>
        <div className="row row-7">
          {currentImages[18] && (
            <div className="image-container" key={18}>
              <img
                src={currentImages[18].url}
                className={`image${18 + 1}`}
                alt={`${18 + 1}`}
                onClick={() => {
                  props.setShowViewImage();
                  props.setImageToShow(currentImages[18]);
                }}
              />
              <p>{currentImages[18].desc}</p>
            </div>
          )}
        </div>
      </div>
      {props.collectionType !== 'about' &&
        props.collectionType !== 'account' && (
          <div className="pagination">
            <button
              className="page-button"
              onClick={() =>
                props.setPageToShow(props.pageToShow > 1 ? props.pageToShow - 1 : props.pageToShow)
              }
            >
              Previous
            </button>
            <p>
              {totalPages === 0 ? 0 : props.pageToShow} out of {totalPages}
            </p>
            <button
              className="page-button"
              onClick={() =>
                props.setPageToShow(
                  props.pageToShow < totalPages ? props.pageToShow + 1 : props.pageToShow
                )
              }
            >
              Next
            </button>
          </div>
        )}
    </div>
  );
};

export default ImageDisplayer;
