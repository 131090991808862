import React, { useState, useEffect } from "react";
import "./Main.css";
import PageHeader from "../components/PageHeader";
import NavMain from "../components/NavMain";
import PageFooter from "../components/PageFooter";
import ImageDisplayer from "../components/image/ImageDisplayer";
import ViewImage from "../components/image/ViewImage";
import HappyTone from "../../images/assets/happy-tone.jpg";
import GrinchTone from "../../images/assets/grinch-tone.jpg";
import GubbyTone from "../../images/assets/gubby-tone.jpg";
import DazedTone from "../../images/assets/dazed-tone.jpg";

const Main = () => {
  const [collectionType, setCollectionType] = useState("about");
  const [showViewImage, setShowViewImage] = useState(false);
  const [imageToShow, setImageToShow] = useState({
    url: "nothing",
    desc: "nothing",
  });
  const [pageToShow, setPageToShow] = useState(0);

  useEffect(() => {
    // Whenever collectionType changes, reset the page number to 1
    setPageToShow(1);
  }, [collectionType]); // This effect runs whenever `collectionType` changes

  return (
    <>
      <div className="main-page">
        <PageHeader />
        <NavMain setCollectionType={setCollectionType} />
        {collectionType === "about" ? (
          <>
            <div className="about-page">
              <h1>ABOUT</h1>
              <h2>Welcome to the one and only Raccoon Finger shop :]</h2>
              <p>
                Here you can view products sold by the Raccoon herself and get
                lost in a vast catalog of her art work
              </p>
              <div className="toni-container">
                <img className="about-image" src={HappyTone} alt="test1" />
                <img className="about-image" src={GrinchTone} alt="test2" />
                <img className="about-image" src={GubbyTone} alt="test3" />
                <img className="about-image" src={DazedTone} alt="test4" />
              </div>
            </div>
          </>
        ) : null}
        {collectionType === "account" ? (
          <>
            <h1>Your Raccoon Finger Account</h1>
            <p>
              Access your account to track orders, save favorite pieces, and
              receive exclusive updates on new artwork and limited edition
              releases.
            </p>
            <button>Login</button>
            <button>Signup</button>
          </>
        ) : null}
        {showViewImage ? (
          <ViewImage
            currentImage={imageToShow}
            setShowViewImage={() => {
              setShowViewImage(false);
            }}
          />
        ) : (
          <ImageDisplayer
            collectionType={collectionType}
            pageToShow={pageToShow}
            setPageToShow={(page) => {
              setPageToShow(page);
            }}
            setShowViewImage={() => {
              setShowViewImage(true);
            }}
            setImageToShow={(image: { url: string; desc: string }) => {
              setImageToShow(image);
            }}
          />
        )}
        <PageFooter />
      </div>
    </>
  );
};

export default Main;
