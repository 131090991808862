import React from 'react';
import './Components.css';

interface PageFooterProps {}

const PageFooter = (props: PageFooterProps) => {
  return (
    <>
      <div className="page-footer"></div>
    </>
  );
};

export default PageFooter;
